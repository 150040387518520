body {
  background: linear-gradient(120deg, #85ffc8 0%, #74caff 100%);
  color: #333;
  font-family: 'Merriweather', serif;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure body covers full viewport height */
  overflow-x: hidden; /* Prevent horizontal overflow */
  padding: 0 10px; /* Add padding to prevent content from touching edges on small screens */
}

input, button {
  margin-top: 10px;
  transition: transform 0.3s ease-in-out;
}

button:hover {
  transform: translateY(-3px); /* Makes button feel interactive */
}

.MuiAppBar-root {
  z-index: 1400; /* Ensure AppBar is on top */
  margin-bottom: 100px; /* Adds space below the AppBar */
  width: 100%; /* Ensure AppBar takes full width */
}

.datePickerFullWidth {
  width: 100%;
}